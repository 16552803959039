import { createApp } from 'vue'
import { createI18n } from 'vue-i18n';
import AOS from 'aos'
import App from './App.vue'
import router from './router'
import store from './store'

import './styles/fontstyles.scss'
import './styles/styles.scss'
import 'aos/dist/aos.css'
import 'bootstrap'
import en from './locales/en_US.json'
import es from './locales/es_CO.json'

const i18n = createI18n({
    locale: 'es',
    fallbackLocale: 'en',
    messages: {
        en,
        es
    },
});

createApp(App)
    .use(store)
    .use(router)
    .use(i18n)
    .mount('#app')

AOS.init()
