export default {
    name: 'catapult',
    component: () => import(/* webpackChunkName: "catapult" */ '@/modules/catapult/layouts/CatapultLayout.vue'),
    children: [
        {
            path: '',
            name: 'catapult_home',
            component: () => import(/* webpackChunkName: "catapult_home" */ '@/modules/catapult/views/CatapultView.vue'),
        }
    ]
}
