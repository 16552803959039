import { createRouter, createWebHashHistory } from 'vue-router'
import catapultRouter from '@/modules/catapult/router'
import catapultTransactionalRouter from '@/modules/catapult_transactional/router'
import AOS from 'aos';
import 'aos/dist/aos.css';

const routes = [
  {
    path: '/',
    redirect: '/catapult'
  },
  {
    path: '/catapult',
    meta: { title: 'Catapult' },
    ...catapultRouter
  },
  {
    path: '/catapult/transactional',
    meta: { title: 'Catapult Transactional' },
    ...catapultTransactionalRouter
  },
  {
    path: '/:pathMatch(.*)*',
    meta: { title: '404 - Page Not Found' },
    component: () => import(/* webpackChunkName: "page_not_found" */ '@/modules/shared/pages/PageNotFound.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        el: to.hash,
        top: 150,
        behavior: 'smooth',
      };
    } else {
      return {
        top: 0,
        behavior: 'smooth',
      }
    }
  },
})

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }
  AOS.init();
  next();
});

export default router
