export default {
    name: 'catapult_transactional',
    component: () => import(/* webpackChunkName: "catapult_transactional" */ '@/modules/catapult_transactional/layouts/CatapultTransactionalLayout.vue'),
    children: [
        {
            path: '',
            name: 'catapult_transactional_home',
            component: () => import(/* webpackChunkName: "catapult_transactional_home" */ '@/modules/catapult_transactional/views/CatapultTransactionalView.vue'),
        }
    ]
}
