<template>
  <router-view/>
</template>

<script>
export default {
  created() {
    this.$router.afterEach((to) => {
      this.changeFavicon(to);
    });
  },
  methods: {
    async changeFavicon(to) {
      const favicon = document.querySelector('link[rel="icon"]');
      if (favicon) {
        if (
          to.path === '/catapult'
          || to.path === '/catapult/transactional'
        ) {
          favicon.href = '/favicon_catapult.ico';
        } else {
          favicon.href = '/favicon.ico';
        }
      }
    }
  }
};
</script>
